import * as React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, graphql, Link } from 'gatsby';
import AlbumList from 'components/album-list/AlbumList';
import { AlbumItem } from 'components/album-item/AlbumItem';
import { AlbumsLayout } from 'components/albums-layout/AlbumsLayout';


export default () => (
  <>
  <Helmet title="Best Albums of 2019">
    <script>
      document.body.classList.add('loading');
    </script>
    <script src={withPrefix('albums.js')} type="text/javascript" />
  </Helmet>

    <AlbumsLayout
      title="Best Albums of 2019"
    >
      <AlbumList>
        <AlbumItem
          position="30"
          artist="Girlpool"
          album="What Chaos is Imaginary"
          quote="Natalie Mering’s fourth album is a grand, sentimental ode to living and loving in the shadow of doom. It is her most ambitious and complex work yet."
          link="https://open.spotify.com/album/58HB0yjdQE2MH5MrfWt4EO?si=WDxaR8EFRVSV4ZkI3hCW5w"
          image={require('assets/images/30-girlpool.jpg')}
        />
        <AlbumItem
          position="29"
          artist="Deerhunter"
          album="Why Hasn't Everything Already Happened?"
          link="https://open.spotify.com/album/68cdl6awj5SQOJXxUwcQHd?si=wE4uvmtNSDedaOFBsR_O7A"
          image={require('assets/images/29-deerhunter.jpg')}
        />
        <AlbumItem
          position="28"
          artist="Billie Eilish"
          album="When We Fall Asleep, Where Do We Go?"
          link="https://open.spotify.com/album/0S0KGZnfBGSIssfF54WSJh?si=4QjpEC8hRVC9Swax5qLJWw"
          image={require('assets/images/28-billie.jpg')}
        />
        <AlbumItem
          position="27"
          artist="Faye Webster"
          album="Atlanta Millionaires Club"
          link="https://open.spotify.com/album/4vt0V1SmkaK1Y440P5Nsb4?si=1vQGA6L6RMC8olJnsyrfBw"
          image={require('assets/images/27-faye.jpg')}
        />
        <AlbumItem
          position="26"
          artist="slowthai"
          album="Nothing Great About Britain"
          link="https://open.spotify.com/album/5Ki4YFQrUx7hCcQx1LJUQb?si=420XaKAwQMqVm5IPiICZ9g"
          image={require('assets/images/26-slowthai.jpg')}
        />
        <AlbumItem
          position="25"
          artist="Little Simz"
          album="Grey Area "
          link="https://open.spotify.com/album/4Wwm4xg2748zhYuzDRFTgY?si=meXLNJyRSPqVZV11BlltQw"
          image={require('assets/images/25-littlesimz.jpg')}
        />
        <AlbumItem
          position="24"
          artist="Tyler, the Creator"
          album="Igor"
          link="https://open.spotify.com/album/5zi7WsKlIiUXv09tbGLKsE?si=9WQUI3e4RBCWTv3nRIHUgw"
          image={require('assets/images/24-tyler.jpg')}
        />
        <AlbumItem
          position="23"
          artist="Julia Jacklin"
          album="Crushing"
          link="https://open.spotify.com/album/6qaaubiJlYCOdYWdqZDl3h?si=seZjuWSGT8WfA-VzKSNmbA"
          image={require('assets/images/23-julia.jpg')}
        />
        <AlbumItem
          position="22"
          artist="Helado Negro"
          album="This Is How You Smile"
          link="https://open.spotify.com/album/17LsQV3q3cgTBrat3D5JSv?si=mXVkA_P2T_2TIm4l9xpQvQ"
          image={require('assets/images/22-helado.jpg')}
        />
        <AlbumItem
          position="21"
          artist="Tropical Fuck Storm"
          album="Braindrop"
          link="https://open.spotify.com/album/3fT7jp89mYZyI3I8HLh7eL?si=JjQh6mHaQrKUr1pXEnVyXg"
          image={require('assets/images/21-tropical.jpg')}
        />
        <AlbumItem
          position="20"
          artist="Purple Mountains"
          album="Purple Mountains"
          link="https://open.spotify.com/album/5NCdiiTgky5PbjmCtcgwtn?si=Zabz7gJbTNi6iNKOKz5S5w"
          image={require('assets/images/20-purple.jpg')}
        />
        <AlbumItem
          position="19"
          artist="Sharon Van Etten"
          album="Remind Me Tomorrow"
          link="https://open.spotify.com/album/2dvXk4nacVRmDSnbKniwrS?si=ERmgDoE4QSa1B6ji7UWDsw"
          image={require('assets/images/19-sharon.jpg')}
        />
        <AlbumItem
          position="18"
          artist="Richard Dawson"
          album="2020"
          link="https://open.spotify.com/album/6IDM4kjUWU82mzE7F9CB8R?si=28eDg_ZjTPex9jV9Fl5ytA"
          image={require('assets/images/18-richard.jpg')}
        />
        <AlbumItem
          position="17"
          artist="Big Thief"
          album="Two Hands"
          link="https://open.spotify.com/album/7pg8T6pajjHVZbiyB8bGxo?si=JLP45KQ1TBaaEELixxT7Mg"
          image={require('assets/images/17-bigthief.jpg')}
        />
        <AlbumItem
          position="16"
          artist="Sault"
          album="5"
          link="https://open.spotify.com/album/57EkTny9UjqpLhFzMO4Hdb?si=NdNJ2F1dRpmIvtcqgEBTTw"
          image={require('assets/images/16-sault.jpg')}
        />

        <AlbumItem
          position="15"
          artist="Angel Olsen"
          album="All Mirrors"
          link="https://open.spotify.com/album/0RedX0LZkGUFoRwFntAaI0?si=a5YNl3i3T8uepYFWiBba_g"
          image={require('assets/images/15-angel.jpg')}
        />
        <AlbumItem
          position="14"
          artist="Kim Gordon"
          album="No Home Record"
          link="https://open.spotify.com/album/4WgO7FEa9fzcyOIabUIbQR?si=57a_kr04QbK1YP3b1xT0dA"
          image={require('assets/images/14-kim.jpg')}
        />
        <AlbumItem
          position="13"
          artist="Vampire Weekend"
          album="Father of the Bride"
          link="https://open.spotify.com/album/1A3nVEWRJ8yvlPzawHI1pQ?si=6wGcSYcyRPqR07qgjPNk0g"
          image={require('assets/images/13-vampire.jpg')}
        />
        <AlbumItem
          position="12"
          artist="Amason"
          album="Galaxy I"
          link="https://open.spotify.com/album/4oX2DTTsFYpQETSpRWIG5y?si=JT99DVhFR32LLaghmVkYMg"
          image={require('assets/images/12-amason.jpg')}
        />
        <AlbumItem
          position="11"
          artist="Jenny Hval"
          album="The Practice of Love"
          link="https://open.spotify.com/album/6Ia2sw3y79k40GHeNjCfLh?si=WReuiAs3Tpyz-Y25wfZcGQ"
          image={require('assets/images/11-jenny.jpg')}
        />
        <AlbumItem
          position="10"
          artist="Crumb"
          album="Jinx"
          link="https://open.spotify.com/album/3vukTUpiENDHDoYTVrwqtz?si=JTZ4JrMNQKWZ_iacpvmfgQ"
          image={require('assets/images/10-crumb.jpg')}
        />
        <AlbumItem
          position="09"
          artist="Aldous Harding"
          album="Designer"
          link="https://open.spotify.com/album/0QNJa03XQeMOuQhi9izThh?si=cNlQ5U-PQlSN0PiYYAbiMg"
          image={require('assets/images/9-aldous.jpg')}
        />
        <AlbumItem
          position="08"
          artist="Kit Sebastian"
          album="Mantra Moderne"
          link="https://open.spotify.com/album/2Uo2xIUyu2VRo43IKxrokH?si=YM50NcqpQm-hk6SUX7erFw"
          image={require('assets/images/8-kit.jpg')}
        />
        <AlbumItem
          position="07"
          artist="Jay Som"
          album="Anak Ko"
          link="https://open.spotify.com/album/62syoe9xkhQbkNOHgNZEQ4?si=P7413YvoSCS4YdiF9E1gKA"
          image={require('assets/images/7-jaysom.jpg')}
        />
        <AlbumItem
          position="06"
          artist="Florist"
          album="Emily Alone"
          link="https://open.spotify.com/album/2Lgoj4yzpi5YchgiVuZTcH?si=erXoArb4RJeqNEECs8PsqA"
          image={require('assets/images/6-florist.jpg')}
        />
        <AlbumItem
          position="05"
          artist="(Sandy) Alex G"
          album="House of Sugar"
          image={require('assets/images/5-sandy.jpg')}
          link="https://open.spotify.com/album/2kCDZ3gCr5hXFgbFsPMcxP?si=sG8W_dlIR_i_vfveqbnYcw"
        />
        <AlbumItem
          position="04"
          artist="Weyes Blood"
          album="Titanic Rising"
          image={require('assets/images/4-weyes.jpg')}
          link="https://open.spotify.com/album/53VKICyqCf91sVkTdFrzKX?si=Jo6LGIwXTxmIRUziQanXMA"
        />
        <AlbumItem
          position="03"
          artist="Bill Callahan"
          album="Shepherd In a Sheepskin Vest"
          image={require('assets/images/3-bill.jpg')}
          link="https://open.spotify.com/album/7sfkWJ14gZywjyv3wtQ5WC?si=GTShDDkGSVWPN23vYiBZQA"
        />
        <AlbumItem
          position="02"
          artist="Cate Le Bon"
          album="Reward"
          image={require('assets/images/2-cate.jpg')}
          link="https://open.spotify.com/album/082FaK20Q5pWBugogTQbGW?si=UV9nbwoNSxybOfEQ_JMyUw"
        />
        <AlbumItem
          position="01"
          artist="Big Thief"
          album="U.F.O.F."
          image={require('assets/images/1-bigthief.jpg')}
          link="https://open.spotify.com/album/7pg8T6pajjHVZbiyB8bGxo?si=fumYWSSMSnK7Ov52gRRdaA"
        />
      </AlbumList>
    </AlbumsLayout>

  </>
);
